import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import lead01 from '../images/lead01.jpg'
import lead02 from '../images/lead02.jpg'
import lead03 from '../images/lead03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">X-Factor</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <h3>What Sets Us Apart </h3>
          <br />

          <div>
            <div className="ft">WE ARE NOT FREELANCERS:</div> <div className="st">All services are rendered by dedicated Xtended.Gh staff. Clients are assigned a team lead who manages workflow and supervises team output. </div> 
            <br />
            <div className="ft">WE DELIVER ON OUR PROMISE:</div> <div className="st">Our robust recruitment, training programs and ongoing supervision of all client services guarantees customer satisfaction 100% of the time. </div>
            <br />
            <div className="ft">NEAR REAL-TIME COLLABORATION:</div> <div className="st">The location of our operations hub in Ghana (West Africa) is most advantageous for real time collaboration with clients across the globe. </div>
            <br />
          </div>

          <div className="statement">
            TAKE THE GAMBLE OUT OF OUTSOURCING AND MOVE FORWARD WITH US.
          </div>
          
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Services</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <div>
            <div className="ft">CATALOG MAINTENANCE:</div> <div className="st">Content optimization and monitoring of product detail pages across all major ecommerce channels </div>
          <br />
            <div className="ft">SALES & MARKETING SUPPORT:</div> <div className="st">Sales analytics, promo execution and category research support. </div>
          <br />
            <div className="ft">INVENTORY MANAGEMENT:</div> <div className="st">Sales velocity and replenishment demand analysis, inventory feed and reconciliation </div>
          <br />
            <div className="ft">CUSTOMER SERVICE:</div> <div className="st">Customer questions, feedback monitoring and response </div>
          </div>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}

          style={{ display: 'none' }}
        >
          <h2 className="major">Leadership</h2>
          
            <div className="gallery">
                  <img src={lead01} alt="" /> 
                  <div className="text">
                  <div className="name">Anna Price</div>
                  <div className="title">Managing Partner</div>
                  <div className="depart">Client Services</div>
                  </div>
                  <div className="icons">
                    <a
                      href="https://www.linkedin.com/in/anna-price-mba/"
                      className="icon fa-linkedin" aria-hidden="true"
                    >
                      <span className="label">Linkedin</span>
                    </a>
                  </div>
            </div>

            <div className="gallery">
                  <img src={lead02} alt="" /> 
                  <div className="text">
                  <div className="name">Yaa Pokoo</div>
                  <div className="title">Managing Partner</div>
                  <div className="depart">Offshore Operations</div>
                  </div>
                  <div className="icons">
                    <a
                      href="https://www.linkedin.com/in/yaa-pokoo-sphri/"
                      className="icon fa-linkedin" aria-hidden="true"
                    >
                      <span className="label">Linkedin</span>
                    </a>
                  </div>
            </div>

            <div className="gallery">
                  <img src={lead03} alt="" />
                  <div className="text">
                    <div className="name">Nate Price</div>
                    <div className="title">Director</div>
                    <div className="depart">IT Systems</div>
                  </div>
                  <div className="icons">
                    <a
                      href="https://www.linkedin.com/in/nate-price-01a48411/"
                      className="icon fa-linkedin" aria-hidden="true"
                    >
                      <span className="label">Linkedin</span>
                    </a>
                  </div>
              </div>

          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="https://getform.io/f/f77fd38d-6b3d-40e9-8ba5-29d0f5545115">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul>
            <li>
              <a className="ft"> +1 (313) 444-3643</a>
            </li>
            <li>
            <a className="ft" href="mailto:info@Xtendedgh.com?subject=Services Inquiry">info@Xtendedgh.com</a>
            </li>
          </ul>
          {/* <ul className="icons">
            <li>
              <a
                href="https://twitter.com/"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://facebook.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://instagram.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul> */}

            
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
